class Bird{
	constructor(init_end){
		//console.log("new Bird")

		const points=[0,-1,-1,1,1,1];
		this.obj3D=new THREE.Object3D();

		/*let birdShape=new THREE.Shape();
		 birdShape.moveTo(points[0],points[1]);
		for(var n=2;n<points.length;n+=2){
			birdShape.lineTo(points[n],points[n+1])
		}
		birdShape.lineTo(points[0],points[1]);

		let geometry=new THREE.ShapeBufferGeometry(birdShape,1);
		let material=new THREE.MeshBasicMaterial({color:0xff0000,side:THREE.DoubleSide});
		let mesh=new THREE.Mesh(geometry,material);
		let scale=3;
		let guideMesh=new THREE.Mesh(new THREE.SphereBufferGeometry(10,16,8),new THREE.MeshStandardMaterial({color:0xff0000}));

		mesh.position.y=1.1*EARTH.circleR/0.3;
		guideMesh.position.y=mesh.position.y;
		guideMesh.position.z=-scale;
		mesh.scale.set(scale,scale,scale);
		mesh.rotation.x=0.5*Math.PI;*/
		let innerObj3D=new THREE.Object3D();
		let scale=3;
		

		//let AxisEuler=new THREE.Quaternion().setFromEuler(new THREE.Euler(-0.5*Math.PI,0,-0.05*Math.PI));
		let AxisEuler=new THREE.Quaternion().setFromEuler(new THREE.Euler(-0.49*Math.PI,0,-0.08*Math.PI));
		this.obj3D.quaternion.copy(AxisEuler);
		
		this.obj3D.add(innerObj3D);
		
		this.obj3D;

		
		let fbxLoader=new THREE.FBXLoader();
		fbxLoader.addParseEvent(loadFbx);
		fbxLoader.load("fbx/kamomeTest1.fbx.zip",1,function(){});

		let birdObj;

		let animationMixer;
		let moveUnitTime=0.04;
		let animationClip;
		let clipAction;

		function loadFbx(e){
			//console.log(e);
			birdObj=e;
			birdObj.children[0].castShadow=true;
			birdObj.scale.set(scale,scale,scale);
			birdObj.position.y=1.05*EARTH.circleR/0.3;
			//birdObj.position.x=10;
			innerObj3D.add(birdObj);

			//console.log(birdObj.children[0].material);

			$.each(birdObj.children[0].material,(id,el)=>{
				el.color=el.color.offsetHSL(0,0,-0.15);
			})

			//.offsetHSL(hueOffset,0,lightOffset);

			//fbx アニメーション

			animationMixer = new THREE.AnimationMixer(birdObj);
			//console.log(animationMixer)
			
			animationClip = birdObj.animations[0];


			clipAction = animationMixer.clipAction(animationClip);

			clipAction.loop=THREE.LoopRepeat;
			
			animationMixer.time=0;

			clipAction.play();


			init_end();
		}
	


		

		let speed=new THREE.Vector2();
		let xSpeedUnit=0.01;
		//let ySpeedUnit=0.01;
		let zSpeedUnit=0.005;

		let nowV2,lastV2=new THREE.Vector2(),difV2;

		let updateCount=0;
		let flappingCount=0;
		let flappingStartCount=100;
		let flappingCountMax=150;


		this.update=function(){
			if(birdObj){

				//console.log("updateCount"+updateCount)
				let speedX=0.075*Math.PI*Math.sin(updateCount*xSpeedUnit);
				let speedZ=0.15*Math.PI*Math.cos(updateCount*zSpeedUnit);

				nowV2=new THREE.Vector2(speedX,speedZ);
				difV2=lastV2.sub(nowV2).clone().normalize();
				lastV2.copy(nowV2);

				let rotateY=Math.atan2(difV2.x,difV2.y)-Math.PI*0.5;

				let SpeedXQ=new THREE.Quaternion().setFromEuler(new THREE.Euler(speedX,0,0));
				let SpeedYQ=new THREE.Quaternion().setFromEuler(new THREE.Euler(0,rotateY,0));
				let SpeedZQ=new THREE.Quaternion().setFromEuler(new THREE.Euler(0,0,speedZ));


				innerObj3D.quaternion.copy(SpeedXQ.multiply(SpeedZQ)).multiply(SpeedYQ);

				
				updateCount+=1;

				//console.log(animationMixer);
				if(flappingCount>flappingStartCount){
					animationMixer.update(moveUnitTime);
				}
				if(flappingCount>flappingStartCount+flappingCountMax){
					flappingCount=0
				}
				flappingCount+=1;
			}

		}

	}


}